import { haveGtmAndGaLoaded } from './AnalyticsHelpers'

export function pushToDataLayer (eventName, customEventProperties) {
  if (process.env.NODE_ENV === 'production' && !haveGtmAndGaLoaded()) return

  const simpleEvent = { event: eventName }

  if (customEventProperties) {
    logCustomEvent(eventName, customEventProperties)
    window.dataLayer.push({
      ...simpleEvent,
      ...customEventProperties
    })
    return
  }

  logSimpleEvent(eventName)
  window.dataLayer.push(simpleEvent)
}

function logSimpleEvent (eventName) {
  if (jsEventTrackingIsEnabled()) {
    console.log('simpleEvent:', eventName)
  }
}

function logCustomEvent (eventName, customEventProperties) {
  if (jsEventTrackingIsEnabled()) {
    console.log('customEvent: ', eventName, '\nProperties: ', JSON.stringify(customEventProperties, null, 2))
  }
}

function jsEventTrackingIsEnabled () {
  const jsEventLogsFlipperValueEl = document.getElementById('vjs-js-event-tracking-logs')
  if (!jsEventLogsFlipperValueEl) return false
  return JSON.parse(jsEventLogsFlipperValueEl.value)
}

// Example POST method implementation:
export function postFetchWrapper (url = '', data = {}, dataType = 'application/json') {
  const token = document.querySelector('meta[name="csrf-token"]').content
  // Default options are marked with *
  const response = window.fetch(url, {
    method: 'POST', // *GET, POST, PUT, DELETE, etc.
    mode: 'cors', // no-cors, *cors, same-origin
    cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
    credentials: 'same-origin', // include, *same-origin, omit
    headers: {
      'Content-Type': 'application/json; charset=utf-8',
      Accept: dataType,
      'X-Requested-With': 'XMLHttpRequest',
      'X-CSRF-Token': token
      // 'Content-Type': 'application/x-www-form-urlencoded',
    },
    keepalive: true, // https://css-tricks.com/send-an-http-request-on-page-exit/
    redirect: 'follow', // manual, *follow, error
    referrerPolicy: 'no-referrer', // no-referrer, *client
    body: JSON.stringify(data) // body data type must match "Content-Type" header
  })
  return response // parses JSON response into native JavaScript objects
}

export function getFetchWrapper (url = '') {
  return baseFetchWrapperWithoutBody('GET', url)
}

export function deleteFetchWrapper (url = '') {
  return baseFetchWrapperWithoutBody('DELETE', url)
}

function baseFetchWrapperWithoutBody (method, url = '') {
  const token = document.querySelector('meta[name="csrf-token"]').content
  // Default options are marked with *
  const response = window.fetch(url, {
    method: method, // *GET, POST, PUT, DELETE, etc.
    mode: 'cors', // no-cors, *cors, same-origin
    cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
    credentials: 'same-origin', // include, *same-origin, omit
    headers: {
      'Content-Type': 'application/json; charset=utf-8',
      Accept: 'application/json',
      'X-Requested-With': 'XMLHttpRequest',
      'X-CSRF-Token': token
      // 'Content-Type': 'application/x-www-form-urlencoded',
    },
    keepalive: true, // https://css-tricks.com/send-an-http-request-on-page-exit/
    redirect: 'follow', // manual, *follow, error
    referrerPolicy: 'no-referrer' // no-referrer, *client
  })
  return response // parses JSON response into native JavaScript objects
}

export function setOpenModalButtons () {
  const modalButtons = document.querySelectorAll('.vjs-modal-button')
  modalButtons.forEach(modalButton => {
    setOpenModalButton(modalButton)
  })
}

export function setOpenModalButton (modalButton) {
  modalButton.addEventListener('click', () => {
    const modalContainer = document.getElementById(modalButton.dataset.modal)
    const previouslyOpenModal = document.querySelector('.vjs-modal.show')

    // Close any previously open modal
    if (previouslyOpenModal) previouslyOpenModal.classList.remove('show')

    if (modalContainer) modalContainer.classList.add('show')
  })
}

function setCloseModalActions () {
  const modals = document.querySelectorAll('.vjs-modal')
  modals.forEach(modalContainer => {
    modalContainer.addEventListener('click', event => {
      if (event.target === modalContainer) {
        modalContainer.classList.remove('show')
      }
    })

    const closeButtons = modalContainer.querySelectorAll('.vjs-modal-close')
    closeButtons.forEach(closeButton => {
      closeButton.addEventListener('click', () => {
        modalContainer.classList.remove('show')
      })
    })
  })
}

export function setModals () {
  setOpenModalButtons()
  setCloseModalActions()
}

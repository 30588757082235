import { pushToDataLayer } from '../PushToDataLayer'
import { pushToFacebookCAPI } from '../PushToFacebookCAPI'
import { pushToAlgoliaAnalytics } from '../Algolia'
import {
  getAllListingAttributes,
  getActionEventId
} from '../AnalyticsHelpers'
import {
  getQueryID,
  getQueryIndex
} from '../../utils/search/helpers'
import { getListingPriceFromDOM } from '../utils/dom_attributes'

export function trackAddToWishlist (listingId) {
  const actionEventId = getActionEventId()
  const listingPrice = getListingPriceFromDOM(listingId)
  const listingIdAsInt = parseInt(listingId)

  algoliaAddToWishlist(listingId)
  pushToFacebookCAPI('AddToWishlist', listingIdAsInt, actionEventId)
  pushToDataLayer('addToWishlist (Vinterior)', {
    listingEngagedWith: { id: listingIdAsInt, price: listingPrice },
    fb_action_event_id: actionEventId
  })
}

export function trackAddToWishlistIntent (initialListingAttributes, clickSource) {
  const listingAttributes = getAllListingAttributes(initialListingAttributes)

  pushToDataLayer('addToWishlistIntent (Vinterior)', {
    listingEngagedWith: listingAttributes,
    clickSource: clickSource
  })
}

export function trackShareWishlist (wishlistId) {
  pushToDataLayer('ga - ee logShareWishListClick', {
    wishListEngagedWith: wishlistId
  })
}

async function algoliaAddToWishlist (listingId) {
  const queryID = await getQueryID(listingId)
  const indexUsed = await getQueryIndex(listingId)

  const commonAlgoliaAnalyticsProperties = {
    index: indexUsed || 'Listing_production',
    objectIDs: [listingId]
  }

  if (queryID && queryID !== 'undefined') {
    const algoliaAnalyticsProperties = {
      eventName: 'Add to Wishlist',
      queryID: queryID,
      ...commonAlgoliaAnalyticsProperties
    }
    pushToAlgoliaAnalytics('convertedObjectIDsAfterSearch', algoliaAnalyticsProperties)
  } else {
    const algoliaAnalyticsProperties = {
      eventName: 'Add to Wishlist - no qID',
      ...commonAlgoliaAnalyticsProperties
    }
    pushToAlgoliaAnalytics('convertedObjectIDs', algoliaAnalyticsProperties)
  }
}

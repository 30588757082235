export function removeExcessWhiteSpace (str) {
  return str.replace(/\s+/g, ' ').trim()
}

export function textEllipsis (str, maxLength, { side = 'end', ellipsis = '...' } = {}) {
  if (str.length <= maxLength) return str
  const newStringLength = maxLength - ellipsis.length
  if (side === 'start') return ellipsis + str.slice(-(newStringLength))
  return str.slice(0, newStringLength) + ellipsis
}

export function capitalize (s) {
  if (typeof s !== 'string') return ''
  return s.charAt(0).toUpperCase() + s.slice(1)
}

export function titleCase (str) {
  const splitStr = str.toLowerCase().split(' ')
  for (let i = 0; i < splitStr.length; i++) {
    splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1)
  }
  return splitStr.join(' ')
}

export function humanizeQueryStringParam (value) {
  return value.replace(/%20/g, ' ').replace(/%3E/g, '/')
}

export function removeQueryString (url) {
  const cleanedUrl = url.split('?')[0]
  if (cleanedUrl === window.location.href) {
    return '#'
  }
  return cleanedUrl
}

export function camelCaseToSnakeCase (str) {
  if (typeof str !== 'string') return str
  return str.replace(/[A-Z]/g, letter => `_${letter.toLowerCase()}`)
}

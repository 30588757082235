import 'url-search-params-polyfill'

export function inExperiment (experimentParam) {
  const urlParams = new URLSearchParams(window.location.search)
  return urlParams.has(experimentParam)
}

export function getExperimentVariant (experimentParam) {
  const urlParams = new URLSearchParams(window.location.search)
  return urlParams.get(experimentParam)
}

// url can be in the form http://localhost:3000/furniture/seating/outdoor-seating?q=
// remove all the host info and all query param too
export function getRoutePath (url) {
  return new URL(url).pathname
}

export function getPathParams (url) {
  return new URL(url).search
}

export function isRoomTypePage (pathname) {
  const roomTypeUrlRegex = /room-type\/.*$/
  if (pathname) return roomTypeUrlRegex.test(pathname)
  return roomTypeUrlRegex.test(window.location.pathname)
}

export function isSeoLocationRoute (location) {
  const pathType = location.pathname.split('/')[1]
  const nonSeoPath = pathType.match(/^(search|collections|sale|new|sellers|showcases)$/g)
  return nonSeoPath === null
}

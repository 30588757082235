import { formDataFetch } from './fetchHelpers'
import { setOpenModalButton } from './modals'
import renderWishlistButton from './listing_tile_rendering/wishlistButtonRenderer'
import { trackAddToWishlist } from '../analytics/events/wishlist'
import { postFetchWrapper } from '../utils/fetchHelpers'
import buildUrl from '../utils/apiHelpers'

export default function setUpAddToWishlistBehaviour () {
  const isLoggedIn = JSON.parse(document.querySelector('body').getAttribute('data-logged-in'))
  if (isLoggedIn) {
    setAddToWishlistModal()
  } else {
    setUpGuestAddToDefaultWishlist()
  }
}

export function setUpGuestAddToDefaultWishlist () {
  const guestWishlistAllowedEl = document.querySelector('#vjs-guest-wishlist-allowed')
  const guestWishlistAllowed = JSON.parse(guestWishlistAllowedEl?.value)
  if (!guestWishlistAllowed) return

  const guestWishlistButtons = Array.from(document.querySelectorAll('.vjs-guest-add-to-wishlist'))
  const csrfToken = document.querySelector('meta[name="csrf-token"]').content

  guestWishlistButtons.forEach(button => {
    button.addEventListener('click', event => {
      const { listingId } = button.dataset
      event.preventDefault()

      const builtUrl = buildUrl('/api/v1/add_to_default_guest_wishlist')
      postFetchWrapper(builtUrl, { wishlist_item: { listing_id: listingId }, authenticity_token: csrfToken })
        .then(response => response.json())
        .then(data => {
          const buttons = Array.from(document.querySelectorAll(`button.vjs-guest-add-to-wishlist[data-listing-id="${listingId}"]`))

          buttons.forEach(el => setWishlistedButton(el, data.newWishListItem.id))
          trackAddToWishlist(listingId)

          notifyUserOfWishlistTemporariness()
        })
    })
  })

  attachRemoveFromWishlistClickListeners()
}

function notifyUserOfWishlistTemporariness () {
  const guestWishlistInfo = document.getElementById('vjs-guest-wishlist-is-temporary-info')

  if (guestWishlistInfo) {
    guestWishlistInfo.classList.remove('hidden')
    setTimeout(() => triggerTransitionToOpacityZero(guestWishlistInfo), 5000)
    setTimeout(() => resetTransitionState(guestWishlistInfo), 5500)
  }
}

function triggerTransitionToOpacityZero (element) {
  element.classList.remove('opacity-1')
  element.classList.add('opacity-0')
}

function resetTransitionState (element) {
  element.classList.remove('opacity-0')
  element.classList.add('opacity-1')
  element.classList.add('hidden')
}

export function setAddToWishlistModal () {
  const addToWishlistButtons = document.querySelectorAll('.vjs-wishlist-modal')
  const wishlistItemsIds = document.querySelector('.vjs-wishlist-item-ids')

  if (wishlistItemsIds && wishlistItemsIds.value) getUserWishlistsRequest()

  addToWishlistButtons.forEach(button => initAddToWishlistModalOnButtonClick(button))

  attachRemoveFromWishlistClickListeners()

  setupWishlistLoginButtons()
  createNewWishlistModal()
}

function attachRemoveFromWishlistClickListeners () {
  const removeFromWishlistButtons = document.querySelectorAll('.vjs-listing-wishlisted')
  removeFromWishlistButtons.forEach(button =>
    button.addEventListener('click', clickEvent => removeFromWishlistRequest(button, clickEvent.currentTarget))
  )
}

function setupWishlistLoginButtons () {
  const loginWishlistButtons = document.querySelectorAll('.vjs-wishlist-login')
  loginWishlistButtons.forEach(button =>
    button.addEventListener('click', () => {
      const listingIdInput = document.getElementById('vjs-login-modal__listing-id')
      if (listingIdInput) {
        listingIdInput.setAttribute('value', button.dataset.listingId)
      } else {
        const input = document.createElement('input')
        input.setAttribute('type', 'hidden')
        input.setAttribute('id', 'vjs-login-modal__listing-id')
        input.setAttribute('name', 'wishlist_item[listing_id]')
        input.setAttribute('value', button.dataset.listingId)
        document.getElementById('new_user')?.appendChild(input)
      }

      const oauthAndRegisterButtons = document.querySelectorAll('.vjs-oauth-button, .vjs-register-here-link')
      oauthAndRegisterButtons.forEach(registerButton => {
        const baseUrl = registerButton.href.split('?')[0]
        registerButton.href = baseUrl + `?wishlist_item[listing_id]=${button.dataset.listingId}`
      })
    })
  )
}

function createNewWishlistModal () {
  const createNewWishlistButton = document.querySelector('.vjs-create-new-wishlist')
  if (!createNewWishlistButton) return

  createNewWishlistButton.addEventListener('click', () => {
    const modalContainer = document.getElementById(createNewWishlistButton.dataset.modal)
    const listingIdInput = modalContainer.querySelector('input[name="listing_id"]')

    listingIdInput.value = createNewWishlistButton.dataset.listingId

    const createNewWishlistForm = modalContainer.querySelector('.vjs-create-new-wishlist-form')

    createNewWishlistForm.addEventListener('submit', submitEvent => {
      submitEvent.preventDefault()
      submitEvent.stopImmediatePropagation()
      createNewWishlistRequest(createNewWishlistButton.dataset.listingId, submitEvent.target)

      if (document.querySelector('.vjs-modal.show')) {
        document.querySelector('.vjs-modal.show').classList.remove('show')
      }

      createNewWishlistForm.reset()
    })
  })
}

function createNewWishlistRequest (listingId, target) {
  const csrfToken = document.querySelector('meta[name="csrf-token"]').content

  window.fetch('/api/v1/wishlists', {
    method: 'POST',
    mode: 'cors',
    credentials: 'same-origin',
    headers: { 'X-CSRF-Token': csrfToken },
    body: new FormData(target)
  })
    .then((response) => {
      return response.json()
    })
    .then((data) => {
      const buttons = document.querySelectorAll(`button.vjs-wishlist-modal[data-listing-id="${listingId}"]`)
      Array.from(buttons).forEach(el => {
        setWishlistedButton(el, data.newWishListItemId)
      })
      getUserWishlistsRequest() // update Wishlist modal
      trackAddToWishlist(listingId)
    })
}

function getUserWishlistsRequest () {
  window.fetch('/api/v1/wishlists', { method: 'GET' })
    .then((response) => {
      return response.json()
    })
    .then((data) => {
      const wishlists = data.wishLists

      // Cleanup Wishlist squares from list on modal
      while (Array.from(document.querySelector('.vjs-user-wishlists').children).length > 1) {
        document.querySelector('.vjs-user-wishlists').removeChild(
          document.querySelector('.vjs-user-wishlists').lastChild
        )
      }

      wishlists.forEach(wishlist => {
        document.querySelector('.vjs-user-wishlists').insertAdjacentHTML('beforeend',
          `<li>
            <form class="vjs-add-to-wishlist-form" action="/api/v1/wishlist_items" method="POST">
              <input type="hidden" name="wishlist_item[listing_id]" value="">
              <input type="hidden" name="wishlist_item[wishlist_id]" value="${wishlist.id}">
              <button type="submit" class="vsd-wishlist-button">
                <img src="${
                    wishlist.cover_image_url
                    ? wishlist.cover_image_url
                    : 'https://static-assets.vinterior.co/wishListTileDefault.png'
                }" />
                <p>${wishlist.title}</p>
              </button>
            </form>
          </li>`
        )
      })
    })
}

function initAddToWishlistModalOnButtonClick (button) {
  button.addEventListener('click', () => {
    const modalContainer = document.getElementById(button.dataset.modal)
    const listingIdInputs = modalContainer.querySelectorAll('input[name="wishlist_item[listing_id]"]')
    const createNewWishlistButton = modalContainer.querySelector('.vjs-create-new-wishlist')

    listingIdInputs.forEach(input => {
      input.value = button.dataset.listingId
    })

    modalContainer.querySelectorAll('.vjs-add-to-wishlist-form')
      .forEach(form => initAddToWishlistModalForms(button, form))

    createNewWishlistButton.dataset.listingId = button.dataset.listingId
  })
}

function initAddToWishlistModalForms (button, form) {
  form.addEventListener('submit', submitEvent => {
    submitEvent.preventDefault()
    submitEvent.stopImmediatePropagation()

    addToWishlistRequest(button, submitEvent.currentTarget)

    if (document.querySelector('.vjs-modal.show')) {
      document.querySelector('.vjs-modal.show').classList.remove('show')
    }
  })
}

function addToWishlistRequest (button, target) {
  const formData = new FormData(target)

  formDataFetch(target)
    .then(response => response.json())
    .then((data) => {
      const listingIdToAdd = formData.get('wishlist_item[listing_id]')
      const wishlistItemIdToAdd = data.newWishListItem.id

      setWishlistedButton(button, wishlistItemIdToAdd)
      getUserWishlistsRequest()
      trackAddToWishlist(listingIdToAdd)
      addToWishlistUpdateDomStore(Number(listingIdToAdd), wishlistItemIdToAdd)
    })
}

function addToWishlistUpdateDomStore (listingIdToAdd, wishlistItemIdToAdd) {
  const wishlistDomStore = document.querySelector('.vjs-wishlist-item-ids').value
  const wishlistIdPairsFromDom = wishlistDomStore.length > 0 ? JSON.parse(wishlistDomStore) : []
  const wishlistIdPairsWithCurrentAdded = [...wishlistIdPairsFromDom, [wishlistItemIdToAdd, listingIdToAdd]]

  document.querySelector('.vjs-wishlist-item-ids').value = JSON.stringify(wishlistIdPairsWithCurrentAdded)
}

function setWishlistedButton (button, wishlistedItemId) {
  const wishlistedButtonHtml = renderWishlistButton(button.dataset.listingId, wishlistedItemId, true, button.dataset.buttonType)
  const wishlistedButton = htmlToElement(wishlistedButtonHtml)

  button.parentNode?.insertBefore(wishlistedButton, button)
  button.remove()

  wishlistedButton.addEventListener('click', clickEvent => removeFromWishlistRequest(wishlistedButton, clickEvent.currentTarget))
}

function removeFromWishlistRequest (button, target) {
  const csrfToken = document.querySelector('meta[name="csrf-token"]').content

  window.fetch(`/api/v1/wishlist_items/${target.dataset.wishlistedItemId}`, {
    method: 'DELETE',
    credentials: 'same-origin',
    headers: {
      'X-CSRF-Token': csrfToken,
      'Content-type': 'application/json; charset=UTF-8'
    }
  })
    .then(response => response.json())
    .then(() => {
      const listingIdToRemove = Number(target.dataset.listingId)
      const wishlistItemIdToRemove = Number(target.dataset.wishlistedItemId)

      setAddToWishlistButton(button)
      removeFromWishlistUpdateDomStore(listingIdToRemove, wishlistItemIdToRemove)
    })
}

function removeFromWishlistUpdateDomStore (listingIdToRemove, wishlistItemIdToRemove) {
  const wishlistDomStore = document.querySelector('.vjs-wishlist-item-ids').value
  const wishlistIdPairsFromDom = wishlistDomStore.length > 0 ? JSON.parse(wishlistDomStore) : []
  const wishlistIdPairsWithCurrentRemoved = wishlistIdPairsFromDom.filter((idPair) => {
    const [wishlistItemId, listingId] = idPair
    return !(wishlistItemId === wishlistItemIdToRemove && listingId === listingIdToRemove)
  })

  document.querySelector('.vjs-wishlist-item-ids').value = JSON.stringify(wishlistIdPairsWithCurrentRemoved)
}

function setAddToWishlistButton (wishlistedButton) {
  const { listingId, buttonType } = wishlistedButton.dataset
  const isLoggedIn = JSON.parse(document.querySelector('body').getAttribute('data-logged-in'))

  const addToWishlistButtonHtml = renderWishlistButton(listingId, null, isLoggedIn, buttonType)
  const addToWishlistButton = htmlToElement(addToWishlistButtonHtml)

  wishlistedButton.parentNode?.insertBefore(addToWishlistButton, wishlistedButton)
  wishlistedButton.remove()

  if (isLoggedIn) {
    // having both lines below is a workaround to allow the modal to open a second time
    // TODO: refactor to avoid this workaround and make more readable.
    setOpenModalButton(addToWishlistButton)
    initAddToWishlistModalOnButtonClick(addToWishlistButton)
  } else {
    setUpGuestAddToDefaultWishlist()
  }
}

// Helper so we can reuse wishlistButtonRenderer
function htmlToElement (html) {
  const div = document.createElement('div')
  div.innerHTML = html.trim()
  return div.firstChild
}

import { humanizeQueryStringParam } from '../utils/stringHelpers'

export function userIDStringFromDataLayerIfUser () {
  if (window.dataLayer[0].user !== undefined) {
    return window.dataLayer[0].user.user_id.toString()
  }
}

export function haveGtmAndGaLoaded () {
  return typeof window.google_tag_manager === 'object' && typeof window.ga === 'function'
}

export function isLoggedInUser () {
  return window.dataLayer && window.dataLayer[0] &&
    window.dataLayer[0].user && window.dataLayer[0].user.is_logged_in
}

export function getAllListingAttributes (listing) {
  return {
    id: listing.objectID === undefined ? listing.id : listing.objectID,
    title: listing.title,
    price: listing.price,
    salePrice: listing.salePrice,
    sale: listing.sale,
    tradePrice: listing.tradePrice,
    trade: listing.trade,
    brand: listing.brand,
    category: listing.category === undefined ? listing.category_name : listing.category,
    designer: listing.designer,
    promotionLevel: listing.promotion_level === undefined ? listing.promotionLevel : listing.promotion_level,
    productType: listing.product_type_name,
    subcategory: listing.subcategory_name,
    vibes: listing.vibe_names === undefined ? '' : listing.vibe_names.join(', '),
    countryOfOrigin: listing.country_of_origin_name_string,
    ageGroup: listing.age_group_name,
    sellerSlug: listing.user_slug,
    color: listing.color_name,
    period: listing.period_name,
    sku: listing.sku
  }
}

function trackSalePrice (listingAttributes) {
  // listing.trade already accounts for whether user is trade
  return listingAttributes.salePrice && listingAttributes.sale === 'true' && listingAttributes.trade === 'false'
}

function trackablePrice (listingAttributes) {
  if (trackSalePrice(listingAttributes)) {
    return listingAttributes.salePrice
  } else if (listingAttributes.trade === 'true') {
    return listingAttributes.tradePrice
  } else {
    return listingAttributes.price
  }
}

export function listingRevenueData (listingAttributes) {
  const trackedPrice = parseFloat(trackablePrice(listingAttributes))
  const originalPrice = parseFloat(listingAttributes.price)
  const data = {
    price: trackedPrice,
    quantity: 1
  }

  if (trackedPrice < originalPrice) {
    const discount = originalPrice - trackedPrice
    data.discount = Math.round((discount + Number.EPSILON) * 100) / 100 // round 2 decimals
  }

  return data
}

export function getActionEventId () {
  return `${new Date().getTime()}.${parseInt((Math.random() * 100000), 10)}`
}

export function getRefinementsListAsKeyValuePairs (formattedParameters) {
  if (!formattedParameters) return []
  const refinementListOfParameters = formattedParameters.split('&')
  return refinementListOfParameters.map((refinement) => {
    const [refinementKey, longRefinementValue] = refinement.split('=')
    const refinementValues = longRefinementValue
      .replace(refinementKey, '')
      .replace('_', '')
      .split('%2B')
    return { refinementKey, refinementValues }
  })
}

// Listing Impressions following the Snowplow schema
export function getSPListingImpressions (hits, query, pageNum) {
  return hits.filter((hit) => { return hit.objectID !== undefined }).map((hit) => {
    const listingId = hit.main_photo ? hit.main_photo.listing_id : null
    const hitsPerPage = 107
    return {
      listing_name: hit.title,
      listing_id: listingId,
      listing_price: hit.price,
      search_query_id: hit.__queryID,
      search_category: hit.category_name,
      listing_seller_id: hit.user_id,
      listing_trade_price: hit.trade_price,
      listing_sale_price: hit.sale_price,
      listing_on_sale: hit.sale,
      listing_sold: hit.sold,
      search_list: query.toLowerCase(),
      search_page_number: pageNum + 1,
      // TODO: was changed from indexOf instead of __position to account for sponsored listings offset
      // review again in case it is clearer to revert to __position
      listing_position: (hits.indexOf(hit) + 1) + (pageNum * hitsPerPage),
      seller_overall_score: hit.seller_overall_score,
      free_uk_delivery: hit.free_uk_delivery_for_algolia?.toString()
    }
  })
}

// Extract and format refinements for Snowplow schema
export function getRefinementListForSPSchema (formattedParameters) {
  const refinementsList = getRefinementsListAsKeyValuePairs(formattedParameters)
  const appliedFilters = refinementsList.reduce((prev, keyValuePair) => {
    const filtersOfCurrentType = keyValuePair.refinementValues.map(filterValues => {
      return {
        filter_name: keyValuePair.refinementKey,
        filter_value: humanizeQueryStringParam(filterValues)
      }
    })
    return [...prev, ...filtersOfCurrentType]
  }, [])
  return appliedFilters
}

export function getRemarketingProperties (listings) {
  const items = listings.map(function (listing) {
    return {
      id: listing.id,
      google_business_vertical: 'retail'
    }
  })
  return {
    items: items
  }
}

export function pushToAlgoliaAnalytics (eventName, customEventProperties) {
  if (jsEventTrackingIsEnabled()) console.log('pushToAlgolia', eventName, customEventProperties)
  if (!window.aa) return
  if (!process.env.NODE_ENV === 'production') return

  window.aa(eventName, customEventProperties)
}

function jsEventTrackingIsEnabled () {
  const jsEventLogsFlipperValueEl = document.getElementById('vjs-js-event-tracking-logs')
  if (!jsEventLogsFlipperValueEl) return false
  return JSON.parse(jsEventLogsFlipperValueEl.value)
}

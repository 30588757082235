import { postFetchWrapper } from '../utils/fetchHelpers'
import buildUrl from '../utils/apiHelpers'

export function pushToFacebookCAPI (eventName, listingId, actionEventId) {
  postFetchWrapper(buildUrl('/api/v1/track_fcapi'), {
    event_name: eventName,
    listing_id: listingId,
    action_event_id: actionEventId
  }).then((response) => response.json())
}
